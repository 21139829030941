import React, { useState } from "react";
import axios from "../../Utils/axios";
import { Button, Typography, Alert,Grid } from "@mui/material";
import { Row, Form, FormGroup, Col, Input } from "reactstrap";
//import Header from '../../components/home/Header';
import contactez_nous from "../../Assets/images/contactez-nous-angelique-hirmance-story.png";
import pinAngelique from "../../Assets/images/pin-icon-contact-page-angelique-story.png";
import mailAngelique from "../../Assets/images/mail-icon-contact-page-angelique-story.png";
import phoneAngelique from "../../Assets/images/phone-icon-contact-page-angelique-story.png";
import "./Contact.css";
import Swal from "sweetalert2";

function Contact() {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    tel: "",
    content: "",
  });

  const [formSubmitted, setFormSubmitted] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post("/public/s/c/contact", formData);
      console.log(response.data);

      setFormSubmitted(true);

      // Display SweetAlert success message
      Swal.fire({
        title: "Message envoyé!",
        text: "Votre message a été envoyé avec succès.",
        icon: "success",
        confirmButtonText: "OK",
        customClass: {
          confirmButton: "swal2-confirm-button",
        },
      }).then(() => {
        // Reload the page after SweetAlert is closed
        window.location.reload();
      });
    } catch (error) {
      console.error(error);
      // Handle error with a SweetAlert error message
      Swal.fire({
        title: "Erreur",
        text: "Une erreur s'est produite. Veuillez réessayer.",
        icon: "error",
        confirmButtonText: "OK",
      });
    }
  };

  const refreshPage = () => {
    window.location.reload();
  };

  return (
    <div>
      <section className="bg-section-contact">
        <img src={contactez_nous} alt="bienvenue_story" className="center_contant" />
        <Grid container spacing={4} justifyContent="center">
      {/* Item 1 */}
      <Grid item xs={12} sm={6} md={4} lg={4} style={{textAlign:"center",padding:"10px"}}>
                  <img
                    src={pinAngelique}
                    alt="bienvenue_story"
                    style={{ width: "25%", margin: "auto", height: "auto" }}
                  />
                  <Typography
                    variant="h4"
                    sx={{
                      color: "#8d7268",
                      textAlign: "center",
                      fontFamily: "Roboto, sans-serif",
                      fontSize: {
                        xs: "14px", // Smallest size for mobile screens
                        sm: "16px", // Slightly larger for small screens
                        md: "18px", // Default size for medium screens
                        lg: "20px", // Larger size for large screens
                        xl: "20px"  // Largest size for extra-large screens
                      },
                      fontStyle: "normal",
                      fontWeight: 400,
                      marginBottom: "50px",
                    }}>
                    17 Rue de Broglie , 66330 Cabestany (Mas Guérido)
                  </Typography>
      </Grid>

      {/* Item 2 */}
      <Grid item xs={12} sm={6} md={4} lg={4} style={{textAlign:"center",padding:"10px"}}>
      <img
                    src={mailAngelique}
                    alt="bienvenue_story"
                    
                    style={{ width: "25%", margin: "auto", height: "auto" }}
                  />
                  <Typography
                    variant="h4"
                    sx={{
                      color: "#8d7268",
                      textAlign: "center",
                      fontFamily: "Roboto, sans-serif",
                      fontSize: {
                        xs: "14px", // Smallest size for mobile screens
                        sm: "16px", // Slightly larger for small screens
                        md: "18px", // Default size for medium screens
                        lg: "20px", // Larger size for large screens
                        xl: "20px"  // Largest size for extra-large screens
                      },
                      fontStyle: "normal",
                      fontWeight: 400,
                      marginBottom: "50px",
                      wordBreak: "break-word", // Handle long text by breaking it
                      overflowWrap: "break-word", // Add additional wrapping behavior

                    }}>
                    echoppe@angeliquehirmancestory.fr
                  </Typography>
      </Grid>

      {/* Item 3 */}
      <Grid item xs={12} sm={6} md={4} lg={4} style={{textAlign:"center",padding:"10px"}}>
      <img
                    src={phoneAngelique}
                    alt="bienvenue_story"
                    
                    style={{ width: "25%", margin: "auto", height: "auto" }}
                  />
                  <Typography
                    variant="h4"
                    sx={{
                      color: "#8d7268",
                      textAlign: "center",
                      fontFamily: "Roboto, sans-serif",
                      fontSize: {
                        xs: "14px", // Smallest size for mobile screens
                        sm: "16px", // Slightly larger for small screens
                        md: "18px", // Default size for medium screens
                        lg: "20px", // Larger size for large screens
                        xl: "20px"  // Largest size for extra-large screens
                      },
                      fontStyle: "normal",
                      fontWeight: 400,
                      marginBottom: "50px",
                    }}>
                    0625740493
                  </Typography>
      </Grid>

      {/* Item 4 */}
    </Grid>
        <Row style={{ paddingBottom: "15%" }}>
          <Col xs="12" className="form-style">
            <Form onSubmit={handleSubmit}>
              <FormGroup row>
                <Col sm={12}>
                  <Input
                    id="service_type"
                    name="service_type"
                    type="select"
                    onChange={handleChange}
                    value={formData.service_type}
                    style={{
                      boxShadow: "rgb(185 200 255 / 7%) -7px 14px 20px 20px",
                    }}>
                    <option value="">Sélectionnez le type de service</option>
                    <option value="Service client">Service client</option>
                    <option value="Service commercial">
                      Service commercial
                    </option>
                    <option value="Service réclamation">
                      Service réclamation
                    </option>
                  </Input>
                </Col>
              </FormGroup>

              <FormGroup row>
                <Col sm={12}>
                  <Input
                    id="name"
                    name="name"
                    placeholder="Prénom / Nom"
                    type="text"
                    onChange={handleChange}
                    value={formData.name}
                    style={{
                      boxShadow: "rgb(185 200 255 / 7%) -7px 14px 20px 20px",
                    }}
                  />
                </Col>
              </FormGroup>
              <FormGroup row>
                <Col sm={12}>
                  <Input
                    id="tel"
                    name="tel"
                    placeholder="Téléphone"
                    type="tel"
                    onChange={handleChange}
                    value={formData.tel}
                    style={{
                      boxShadow: "rgb(185 200 255 / 7%) -7px 14px 20px 20px",
                    }}
                  />
                </Col>
              </FormGroup>
              <FormGroup row>
                <Col sm={12}>
                  <Input
                    id="email"
                    name="email"
                    placeholder="Email"
                    type="email"
                    onChange={handleChange}
                    value={formData.email}
                    style={{
                      boxShadow: "rgb(185 200 255 / 7%) -7px 14px 20px 20px",
                    }}
                  />
                </Col>
              </FormGroup>
              <FormGroup row>
                <Col sm={12}>
                  <Input
                    id="content"
                    name="content"
                    type="textarea"
                    onChange={handleChange}
                    value={formData.content}
                    style={{
                      boxShadow: "rgb(185 200 255 / 7%) -7px 14px 20px 20px",
                    }}
                  />
                </Col>
              </FormGroup>

              <Button
                type="submit"
                style={{ backgroundColor: "#f3778f", color: "white" }}>
                Envoyer !
              </Button>
            </Form>
            {formSubmitted && (
              <div style={{ marginTop: "20px" }}>
                <Alert severity="success">Message envoyée avec succès !</Alert>
               {/* Refresh the page when alert is displayed   {refreshPage()} */}
              </div>
            )}
          </Col>
        </Row>
      </section>

      {/* <section className="bg-section-contact-responsive-tablet">
        <img
          src={contactez_nous}
          alt="bienvenue_story"
          className="center"
          style={{ height: "auto", width: "100%" }}
        />
        <br></br>

        <Col xs="12" className="col-info-box">
          <Row>
            <Row>
              <img
                src={pinAngelique}
                alt="bienvenue_story"
                className="icon-width"
              />

              <Typography
                variant="h4"
                style={{
                  color: "#8d7268",
                  textAlign: "center",
                  fontFamily: "Roboto, sans-serif",
                  fontSize: "20px",
                  fontStyle: "normal",
                  fontWeight: 400,
                  marginBottom: "50px",
                }}>
                17 Rue de Broglie , 66330 Cabestany (Mas Guérido)
              </Typography>
            </Row>

            <Row>
              <img
                src={mailAngelique}
                alt="bienvenue_story"
                className="icon-width"
              />

              <Typography
                variant="h4"
                style={{
                  color: "#8d7268",
                  textAlign: "center",
                  fontFamily: "Roboto, sans-serif",
                  fontSize: "20px",
                  fontStyle: "normal",
                  fontWeight: 400,
                  marginBottom: "50px",
                }}>
                serviceclient@angeliquehirmancestory.fr
              </Typography>
            </Row>

            <Row>
              <img
                src={phoneAngelique}
                alt="bienvenue_story"
                className="icon-width"
              />

              <Typography
                variant="h4"
                style={{
                  color: "#8d7268",
                  textAlign: "center",
                  fontFamily: "Roboto, sans-serif",
                  fontSize: "20px",
                  fontStyle: "normal",
                  fontWeight: 400,
                  marginBottom: "50px",
                }}>
                0625740493
              </Typography>
            </Row>

            <Col xs="12" className="form-style">
              <Form onSubmit={handleSubmit}>
                <FormGroup row>
                  <Col sm={12}>
                    <Input
                      id="service_type"
                      name="service_type"
                      type="select"
                      onChange={handleChange}
                      value={formData.service_type}
                      style={{
                        boxShadow: "rgb(185 200 255 / 7%) -7px 14px 20px 20px",
                      }}>
                      <option value="">Sélectionnez le type de service</option>
                      <option value="Service client">Service client</option>
                      <option value="Service commercial">
                        Service commercial
                      </option>
                      <option value="Service réclamation">
                        Service réclamation
                      </option>
                    </Input>
                  </Col>
                </FormGroup>
                <FormGroup row>
                  <Col sm={12}>
                    <Input
                      id="name"
                      name="name"
                      placeholder="Prénom / Nom"
                      type="text"
                      onChange={handleChange}
                      value={formData.name}
                      style={{
                        boxShadow: "rgb(185 200 255 / 7%) -7px 14px 20px 20px",
                      }}
                    />
                  </Col>
                </FormGroup>
                <FormGroup row>
                <Col sm={12}>
                  <Input
                    id="tel"
                    name="tel"
                    placeholder="Téléphone"
                    type="tel"
                    onChange={handleChange}
                    value={formData.tel}
                    style={{
                      boxShadow: "rgb(185 200 255 / 7%) -7px 14px 20px 20px",
                    }}
                  />
                </Col>
              </FormGroup>
                <FormGroup row>
                  <Col sm={12}>
                    <Input
                      id="email"
                      name="email"
                      placeholder="Email"
                      type="email"
                      onChange={handleChange}
                      value={formData.email}
                      style={{
                        boxShadow: "rgb(185 200 255 / 7%) -7px 14px 20px 20px",
                      }}
                    />
                  </Col>
                </FormGroup>
                <FormGroup row>
                  <Col sm={12}>
                    <Input
                      id="content"
                      name="content"
                      type="textarea"
                      onChange={handleChange}
                      value={formData.content}
                      style={{
                        boxShadow: "rgb(185 200 255 / 7%) -7px 14px 20px 20px",
                      }}
                    />
                  </Col>
                </FormGroup>

                <Button
                  type="submit"
                  style={{ backgroundColor: "#8d7268", color: "white" }}>
                  Envoyer !
                </Button>
                <br></br>
              </Form>

              {formSubmitted && (
                <div style={{ marginTop: "20px" }}>
                  <Alert severity="success">
                    Message envoyée avec succès !
                  </Alert>
                 
                  
                </div>
              )}
            </Col>
          </Row>
        </Col>
      </section>

      <section className="bg-section-contact-responsive-mobile">
        <img
          src={contactez_nous}
          alt="bienvenue_story"
          className="center"
          style={{ height: "auto", width: "100%" }}
        />
        <br></br>

        <Col>
          <Row>
            <img
              src={pinAngelique}
              alt="bienvenue_story"
              className="icon-width"
            />
          </Row>
          <Row>
            <Typography
              variant="h4"
              style={{
                color: "#8d7268",
                textAlign: "center",
                fontFamily: "Roboto, sans-serif",
                fontSize: "20px",
                fontStyle: "normal",
                fontWeight: 400,
                marginBottom: "50px",
              }}>
              17 Rue de Broglie , 66330 Cabestany (Mas Guérido)
            </Typography>
          </Row>
        </Col>
        <Col>
          <Row>
            <img
              src={mailAngelique}
              alt="bienvenue_story"
              className="icon-width"
            />
          </Row>
          <Row>
            <Typography
              variant="h4"
              style={{
                color: "#8d7268",
                textAlign: "center",
                fontFamily: "Roboto, sans-serif",
                fontSize: "20px",
                fontStyle: "normal",
                fontWeight: 400,
                marginBottom: "50px",
              }}>
              serviceclient@angeliquehirmancestory.fr
            </Typography>
          </Row>
        </Col>
        <Col>
          <Row>
            <img
              src={phoneAngelique}
              alt="bienvenue_story"
              className="icon-width"
            />
          </Row>
          <Row>
            <Typography
              variant="h4"
              style={{
                color: "#8d7268",
                textAlign: "center",
                fontFamily: "Roboto, sans-serif",
                fontSize: "20px",
                fontStyle: "normal",
                fontWeight: 400,
                marginBottom: "50px",
              }}>
              0625740493
            </Typography>
          </Row>
        </Col>
        <Col xs="12" className="form-style">
          <Form>
            <FormGroup row>
              <Col sm={10}>
                <Input
                  id="service_type"
                  name="service_type"
                  type="select"
                  onChange={handleChange}
                  value={formData.service_type}
                  style={{
                    boxShadow: "rgb(185 200 255 / 7%) -7px 14px 20px 20px",
                  }}>
                  <option value="">Sélectionnez le type de service</option>
                  <option value="Service client">Service client</option>
                  <option value="Service commercial">Service commercial</option>
                  <option value="Service réclamation">
                    Service réclamation
                  </option>
                </Input>
              </Col>
            </FormGroup>
            <FormGroup row>
              <Col sm={12}>
                <Input
                  id="nom"
                  name="nom"
                  placeholder="Prénom / Nom"
                  type="text"
                  style={{
                    boxShadow: "rgb(185 200 255 / 7%) -7px 14px 20px 20px",
                  }}
                />
              </Col>
            </FormGroup>
            <FormGroup row>
                <Col sm={12}>
                  <Input
                    id="tel"
                    name="tel"
                    placeholder="Téléphone"
                    type="tel"
                    onChange={handleChange}
                    value={formData.tel}
                    style={{
                      boxShadow: "rgb(185 200 255 / 7%) -7px 14px 20px 20px",
                    }}
                  />
                </Col>
              </FormGroup>
            <FormGroup row>
              <Col sm={12}>
                <Input
                  id="email"
                  name="email"
                  placeholder="Email"
                  type="email"
                  style={{
                    boxShadow: "rgb(185 200 255 / 7%) -7px 14px 20px 20px",
                  }}
                />
              </Col>
            </FormGroup>
            <FormGroup row>
              <Col sm={12}>
                <Input
                  id="message"
                  name="message"
                  type="textarea"
                  style={{
                    boxShadow: "rgb(185 200 255 / 7%) -7px 14px 20px 20px",
                  }}
                />
              </Col>
            </FormGroup>

            <Button style={{ backgroundColor: "#8d7268", color: "white" }}>
              Envoyer !
            </Button>
          </Form>
        </Col>
      </section> */}
    </div>
  );
}

export default Contact;
