import React from "react";
import { NavLink } from "react-router-dom";
import { Card, CardBody, CardTitle, CardSubtitle } from "reactstrap";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "@fortawesome/fontawesome-free/css/all.min.css";
import "./SubscriptionCard.css";
import GlobalContext from "../../../../../../../Context/GlobalContext";
import rounded_back from "../../../../../../../Assets/images/Ellipse 5.png";
import { fontSize } from "@mui/system";

export default function SubscriptionCard(props) {
  const { setSelectedSubsId } = React.useContext(GlobalContext);

  const maxLength = 300; // Nombre maximum de caractères à afficher
  const truncatedDescription =
    props.subs.description_subs.length > maxLength
      ? props.subs.description_subs.substring(0, maxLength) + "..."
      : props.subs.description_subs;

  const descriptionArray = truncatedDescription.split('.').filter(Boolean); // Split by period and remove empty strings

  const handleEnSavoirPlusClick = () => {
    setSelectedSubsId(props.subs.id_subs);
  };
  
  return (
    <div className="subscription-card parent-container">
      <Card style={{ width: "18rem" }} className="h-100">
        <CardBody style={{ backgroundColor: '#F3778F', borderRadius: '20px', padding: '2rem' }}>
          <CardTitle tag="h2" style={{ color: "#ffffff", textAlign: "center" }}>
            <b>{props.subs.name_subs}</b><br></br>
            <span className="text-muted m-l-10">
              <sup style={{color: "#ffffff"}}> par mois</sup>
            </span>
          </CardTitle>
          <div className="subscription-details" style={{ backgroundImage: `url(${rounded_back})` }}>
            <h3 className="plan-price padding-b-15" style={{ color: '#F3778F', fontWeight: 600}}>
              {props.subs.price_subs} €
            </h3>
          </div>
          <CardSubtitle
            className="mb-2"
            tag="h6"
            style={{ color: "#ffffff", textAlign: "Left", padding: '30px 0 20px 0',fontSize: "14px" }}
          >
            {descriptionArray.map((line, index) => (
              <React.Fragment key={index}>
                <span style={{ color: '#ffffff' }}>✔</span> {/* Apply green color */}
                {line.trim()} {/* Trim to remove leading/trailing whitespaces */}
                <br />
              </React.Fragment>
            ))}
          </CardSubtitle>
          <center>
            <NavLink
              to={`/public/subs/g/${props.subs.id_subs}`}
              className="btn btn-sm abonner"
              onClick={handleEnSavoirPlusClick}
            >
              Je m’abonne
            </NavLink>
          </center>
        </CardBody>
      </Card>
    </div>
  );
}
