import React from "react";
import { Typography } from "@mui/material";
import styles from "./Boutique.module.css";
import { useTheme, useMediaQuery } from "@mui/material";

function BoutiqueEnligne({userData}) {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));


  const handleButtonClick = () => {
    const link = userData.lien_boutique?.trim(); // Trim whitespace
    console.log("Boutique Link:", userData.lien_boutique);
    // Check if the link exists
    if (link) {
      // Open the link in a new tab
      window.open(link, "_blank");
    } else {
      // Alert if the link is unavailable
      alert("Lien boutique n'est pas encore disponible !");
    }
  };

  
  return (
    <div className={styles["boutique-section"]}>
      <Typography
        variant="h4"
        style={{
          color: "#f3778f",
          textAlign: "center",
          fontFamily: "Majetto",
          fontSize: isSmallScreen ? "1.5rem" : "3.5rem",
          fontStyle: "normal",
          fontWeight: 400,
          textTransform: "uppercase",
          "@media (max-width: 768px)": {
            fontSize: "6vw",
          },
        }}
      >
        Ma boutique
      </Typography>
      <Typography
        variant="h4"
        sx={{
            color: "var(--taupe-fonc, #66534D)",
            textAlign: "center",
            fontFamily: "Roboto",
            fontSize: "19px",
            fontStyle: "normal",
            fontWeight: 400,
            width: "50%",
            whiteSpace: "pre-line",
            "@media (max-width: 600px)": {
              width: "100%",
            },
          }}
      >
        Homines est dierum quod consilium consilium idem gratulatio consilium
        Rei causa reprehendant decreverim pertinerent dignitatem et decrevi quam
        ex multa publicae Mario dignitatem mea causa quod consilium hominis Rei.
      </Typography>
      <button className={styles["btn-more"]} onClick={handleButtonClick}>
        Je Découvre !
      </button>
    </div>
  );
}

export default BoutiqueEnligne;
