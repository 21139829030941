// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Boutique_btn-more__klZra {
  border: 2px solid #f3778f;
  background-color: #f3778f;
  color: #ffffff;
  padding: 10px 28px;
  font-size: 16px;
  font-weight: 500;
  cursor: pointer;
  border-radius: 10px;
  cursor: pointer;
  transition: background-color 0.3s ease-in-out;
}
.Boutique_btn-more__klZra:hover{
 
      background-color: transparent;
      color: #f3778f;
      border: 2px solid #f3778f;
    
}

.Boutique_boutique-section__mIs\\+i {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  gap: 3vh;
}`, "",{"version":3,"sources":["webpack://./src/Pages/IntervenantsPage/Intervenants/ProfileIntervenant/Boutique/Boutique.module.css"],"names":[],"mappings":"AAAA;EACE,yBAAyB;EACzB,yBAAyB;EACzB,cAAc;EACd,kBAAkB;EAClB,eAAe;EACf,gBAAgB;EAChB,eAAe;EACf,mBAAmB;EACnB,eAAe;EACf,6CAA6C;AAC/C;AACA;;MAEM,6BAA6B;MAC7B,cAAc;MACd,yBAAyB;;AAE/B;;AAEA;EACE,aAAa;EACb,sBAAsB;EACtB,mBAAmB;EACnB,uBAAuB;EACvB,kBAAkB;EAClB,QAAQ;AACV","sourcesContent":[".btn-more {\n  border: 2px solid #f3778f;\n  background-color: #f3778f;\n  color: #ffffff;\n  padding: 10px 28px;\n  font-size: 16px;\n  font-weight: 500;\n  cursor: pointer;\n  border-radius: 10px;\n  cursor: pointer;\n  transition: background-color 0.3s ease-in-out;\n}\n.btn-more:hover{\n \n      background-color: transparent;\n      color: #f3778f;\n      border: 2px solid #f3778f;\n    \n}\n\n.boutique-section {\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  justify-content: center;\n  text-align: center;\n  gap: 3vh;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"btn-more": `Boutique_btn-more__klZra`,
	"boutique-section": `Boutique_boutique-section__mIs+i`
};
export default ___CSS_LOADER_EXPORT___;
