import React from "react";
import { Card, CardBody, CardTitle, CardSubtitle, CardText } from "reactstrap";
import styled from "styled-components";
import Slider from "react-slick";
import { Typography, Grid } from "@mui/material";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import onlineIcon from "../../../../Assets/images/Online-icon-angelique-story.png";
import arrowDown from "../../../../Assets/images/angle-double-down.png";
import offlineIcon from "../../../../Assets/images/sur_RDV_icone.png";
import bienvenue_story from "../../../../Assets/images/bienvenue_story-1.png";
import logo_yt from "../../../../Assets/images/youtube.png";
import intervenant from "../../../../Assets/images/accueil-nos-intervenants-story.png";
import abonnez_vous from "../../../../Assets/images/accueil-abonnez-vous-story.png";
import a_boutique from "../../../../Assets/images/accueil-a-boutique-story.png";
import paxk_jetons from "../../../../Assets/images/accueil-pack jetons-story.png";
import "./Header.css";
import Rating from "react-rating-stars-component";
import { useNavigate, NavLink } from "react-router-dom";
import GlobalContext from "../../../../Context/GlobalContext";
import Skeleton from "@mui/material/Skeleton";

const GradientCardBody = styled(CardBody)`
  border-radius: 20px;
`;

const CardContainer = styled.div`
  margin: 0 auto;
`;

function Header() {
  const navigate = useNavigate();
  const context = React.useContext(GlobalContext);

  const handleEnSavoirPlusClick = (id_user) => {
    context.setSelectedIntervenantId(id_user);
  };

  // Filter items to include only active users
  /*  const onlineUsers = context.intervenantsData ? context.intervenantsData.filter(
    (item) => item.availability_user === 1
  ) : []; */

  const Users = context.intervenantsData || [];

  // Adjust the slidesToShow based on the number of available cards
  const slidesToShowCount = Users.length < 4 ? Users.length : 4;

  const sliderSettings = {
    arrows: true,
    style: { maxWidth: "80%", margin: "50px auto" },
    prevArrow: <div className="slick-prev"></div>,
    nextArrow: <div className="slick-next"></div>,
    infinite: Users.length >= 4, // Infinite scroll only when more than 4 users
    dots: false,
    speed: 500,
    slidesToShow: slidesToShowCount, // Dynamically set slidesToShow
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
    centerMode: false,
    responsive: [
      {
        breakpoint: 1570, // For large screens
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          arrows: true,
          dots: false,
          style: {
            maxWidth: "80%",
            margin: "0 auto",
          },
        },
      },
      {
        breakpoint: 1200, // For large screens
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          arrows: true,
          dots: false,
          style: {
            maxWidth: "80%",
            margin: "0 auto",
          },
        },
      },
      {
        breakpoint: 768, // For small screens (phones)
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          arrows: false,
          dots: true,
          style: {
            maxWidth: "100%",
            margin: "0 auto",
          },
        },
      },
    ],
  };

  if (!context.intervenantsData) {
    return (
      <section className="bg-header">
        <div className="first_row">
          {/* First column: Empty, acting as a spacer */}
          <div className="column" style={{ flex: "1 1 40%" }}>
            <img src={bienvenue_story} alt="bienvenue_story" className="left" />
            <Typography
              variant="h4"
              sx={{
                color: "var(--taupe-fonc, #66534D)",
                textAlign: "left",
                fontFamily: "Majetto",
                fontSize: {
                  xs: "6vw",
                  sm: "1.5vw",
                  md: "1.5vw",
                  lg: "1.5vw",
                  xl: "1.5vw",
                },
                fontStyle: "normal",
                fontWeight: 500,
              }}>
              Découvrez la clarté et l'inspiration grâce<br></br> à nos voyants
              experts
            </Typography>
          </div>
          {/* Second column: Contains the image and Typography */}
          <div className="column" style={{ flex: "1 1 40%", padding: "4%" }}>
            <Grid
              container
              spacing={{ xs: 5, md: 3 }}
              columns={{ xs: 4, sm: 8, md: 12 }}>
              <Grid item xs={2} sm={4} md={6} style={{ textAlign: "center" }}>
              <NavLink to="/intervenants" style={{textDecoration:"none"}}>
             
                <img  src={intervenant} alt="intervenant" style={{ width: "80%", height: "auto" }} />
                
                <Typography
                  variant="h4"
                  sx={{
                    color: '#8D7268',
                    fontFamily: 'Majetto',
                    fontSize: {
                      xs: "14px", // Smallest size for mobile screens
                      sm: "16px", // Slightly larger for small screens
                      md: "18px", // Default size for medium screens
                      lg: "20px", // Larger size for large screens
                      xl: "20px"  // Largest size for extra-large screens
                    },
                    fontStyle: 'normal',
                    fontWeight: 500,
                    marginTop: '10px'
                  }}
                >
                  Intervenants
                </Typography>
                </NavLink>
              </Grid>

              <Grid item xs={2} sm={4} md={6} style={{ textAlign: "center" }}>
              <NavLink to="/subscriptions" style={{textDecoration:"none"}}>
             
                <img src={abonnez_vous} alt="abonnez_vous" style={{ width: "80%", height: "auto" }} />
                
                <Typography
                  variant="h4"
                  sx={{
                    color: '#8D7268',
                    fontFamily: 'Majetto',
                    fontSize: {
                      xs: "14px", // Smallest size for mobile screens
                      sm: "16px", // Slightly larger for small screens
                      md: "18px", // Default size for medium screens
                      lg: "20px", // Larger size for large screens
                      xl: "20px"  // Largest size for extra-large screens
                    },
                    fontStyle: 'normal',
                    fontWeight: 500,
                    marginTop: '10px'
                  }}
                >
                  Abonnez-vous
                </Typography>
                </NavLink>
              </Grid>

              <Grid item xs={2} sm={4} md={6} style={{ textAlign: "center" }}>
              <NavLink to="/packs-jetons" style={{textDecoration:"none"}}>
             
                <img src={paxk_jetons} alt="pack_jetons" style={{ width: "80%", height: "auto" }} />
                
                <Typography
                  variant="h4"
                  sx={{
                    color: '#8D7268',
                    fontFamily: 'Majetto',
                    fontSize: {
                      xs: "14px", // Smallest size for mobile screens
                      sm: "16px", // Slightly larger for small screens
                      md: "18px", // Default size for medium screens
                      lg: "20px", // Larger size for large screens
                      xl: "20px"  // Largest size for extra-large screens
                    },
                    fontStyle: 'normal',
                    fontWeight: 500,
                    marginTop: '10px'
                  }}
                >
                  Pack Jetons
                </Typography>
                </NavLink>
              </Grid>

              <Grid item xs={2} sm={4} md={6} style={{ textAlign: "center" }}>
              <a href="https://angeliquehirmancestory.fr/" target="_blank" style={{textDecoration:"none"}}>
              
                <img src={a_boutique} alt="e_boutique" style={{ width: "80%", height: "auto" }} />
                
                <Typography
                  variant="h4"
                  sx={{
                    color: '#8D7268',
                    fontFamily: 'Majetto',
                    fontSize: {
                      xs: "14px", // Smallest size for mobile screens
                      sm: "16px", // Slightly larger for small screens
                      md: "18px", // Default size for medium screens
                      lg: "20px", // Larger size for large screens
                      xl: "20px"  // Largest size for extra-large screens
                    },
                    fontStyle: 'normal',
                    fontWeight: 500,
                    marginTop: '10px'
                  }}
                >
                  E-Boutique
                </Typography>
                </a>
              </Grid>
            </Grid>
          </div>
          {/* Third column: Empty, acting as a spacer */}
          <div className="column" style={{ flex: "1 1 20%" }}>
            <NavLink to="/guide-utilisation">
              <img
                src={logo_yt}
                alt="logo_youtube"
                className="logo_youtube center"
                width={"30%"}
              />
            </NavLink>
          </div>
        </div>
        <br />
        <div>
          <img
                  src={arrowDown}
                  alt="arrow_Down"
                  className="arrowDown center"
                  
                />
        </div>
        <br></br>
        <div className="second_row">
          <Slider {...sliderSettings}>
            {Array.from({ length: 3 }).map((_, index) => (
              <CardContainer key={index}>
                <Card style={{ width: "18rem" }} className="h-100">
                  <Skeleton variant="rectangular" height={270} />
                  <GradientCardBody>
                    <CardTitle tag="h5">
                      <Skeleton variant="text" width={100} />
                    </CardTitle>
                    <CardSubtitle tag="h6">
                      <Skeleton variant="text" width={80} />
                    </CardSubtitle>
                    <CardText
                      style={{ display: "flex", justifyContent: "center" }}>
                      <Skeleton variant="text" width={150} height={40} />
                    </CardText>
                    <div style={{ display: "flex", justifyContent: "center" }}>
                      <Skeleton variant="text" width={100} height={30} />
                    </div>
                  </GradientCardBody>
                </Card>
              </CardContainer>
            ))}
          </Slider>
        </div>
        <div className="div-more">
          <center>
            <button
              className="btn-more"
              onClick={() => navigate("/intervenants")}
              disabled>
              Voir plus d’intervenants
            </button>
          </center>
        </div>
      </section>
    );
  }

  return (
    <div>
      <section className="bg-header">
        <div className="first_row">
          {/* First column: Empty, acting as a spacer */}
          <div className="column" style={{ flex: "1 1 40%" }}>
          <img src={bienvenue_story} alt="bienvenue_story" className="left" />
          <Typography
              variant="h4"
              sx={{
                color: "var(--taupe-fonc, #66534D)",
                textAlign: "left",
                fontFamily: "Majetto",
                fontSize: {
                  xs: "3.5vw",
                  sm: "3.5vw",
                  md: "1.5vw",
                  lg: "1.5vw",
                  xl: "1.5vw",
                },
                fontStyle: "normal",
                fontWeight: 500,
              }}>
              Découvrez la clarté et l'inspiration grâce<br></br> à nos voyants
              experts
            </Typography>
          </div>
          {/* Second column: Contains the image and Typography */}
          <div className="column" style={{ flex: "1 1 40%", padding: "4%" }}>
            <Grid
              container
              spacing={{ xs: 5, md: 3 }}
              columns={{ xs: 4, sm: 8, md: 12 }}>
              <Grid item xs={2} sm={4} md={6} style={{ textAlign: "center" }}>
              <NavLink to="/intervenants" style={{textDecoration:"none"}}>
                <img src={intervenant} alt="intervenant" style={{ width: "80%", height: "auto" }} />
                <Typography
                  variant="h4"
                  sx={{
                    color: '#8D7268',
                    fontFamily: 'Majetto',
                    fontSize: {
                      xs: "14px", // Smallest size for mobile screens
                      sm: "16px", // Slightly larger for small screens
                      md: "18px", // Default size for medium screens
                      lg: "20px", // Larger size for large screens
                      xl: "20px"  // Largest size for extra-large screens
                    },
                    fontStyle: 'normal',
                    fontWeight: 500,
                    marginTop: '10px'
                  }}
                >
                  Intervenants
                </Typography>
                </NavLink>
              </Grid>
              <Grid item xs={2} sm={4} md={6} style={{ textAlign: "center" }}>
              <NavLink to="/subscriptions" style={{textDecoration:"none"}}>
              
                <img src={abonnez_vous} alt="abonnez_vous" style={{ width: "80%", height: "auto" }} />
                <Typography
                  variant="h4"
                  sx={{
                    color: '#8D7268',
                    fontFamily: 'Majetto',
                    fontSize: {
                      xs: "14px", // Smallest size for mobile screens
                      sm: "16px", // Slightly larger for small screens
                      md: "18px", // Default size for medium screens
                      lg: "20px", // Larger size for large screens
                      xl: "20px"  // Largest size for extra-large screens
                    },
                    fontStyle: 'normal',
                    fontWeight: 500,
                    marginTop: '10px'
                  }}
                >
                  Abonnez-vous
                </Typography>
                </NavLink>
              </Grid>

              <Grid item xs={2} sm={4} md={6} style={{ textAlign: "center" }}>
              <NavLink to="/packs-jetons" style={{textDecoration:"none"}}>
              
                <img src={paxk_jetons} alt="pack_jetons" style={{ width: "80%", height: "auto" }} />
                <Typography
                  variant="h4"
                  sx={{
                    color: '#8D7268',
                    fontFamily: 'Majetto',
                    fontSize: {
                      xs: "14px", // Smallest size for mobile screens
                      sm: "16px", // Slightly larger for small screens
                      md: "18px", // Default size for medium screens
                      lg: "20px", // Larger size for large screens
                      xl: "20px"  // Largest size for extra-large screens
                    },
                    fontStyle: 'normal',
                    fontWeight: 500,
                    marginTop: '10px'
                  }}
                >
                  Pack Jetons
                </Typography>
                </NavLink>
              </Grid>

              <Grid item xs={2} sm={4} md={6} style={{ textAlign: "center" }}>
              <a href="https://angeliquehirmancestory.fr/" target="_blank" style={{textDecoration:"none"}}>
                <img src={a_boutique} alt="e_boutique" style={{ width: "80%", height: "auto" }} />
                <Typography
                  variant="h4"
                  sx={{
                    color: '#8D7268',
                    fontFamily: 'Majetto',
                    fontSize: {
                      xs: "14px", // Smallest size for mobile screens
                      sm: "16px", // Slightly larger for small screens
                      md: "18px", // Default size for medium screens
                      lg: "20px", // Larger size for large screens
                      xl: "20px"  // Largest size for extra-large screens
                    },
                    fontStyle: 'normal',
                    fontWeight: 500,
                    marginTop: '10px'
                  }}
                >
                  E-Boutique
                </Typography>
                </a>
              </Grid>
            </Grid>
          </div>
          {/* Third column: Empty, acting as a spacer */}
          <div className="column" style={{ flex: "1 1 20%" }}>
            <NavLink to="/guide-utilisation">
              <img
                src={logo_yt}
                alt="logo_youtube"
                className="logo_youtube center"
                width={"30%"}
              />
            </NavLink>
          </div>
        </div>
        <br />
        <div>
          <img
                  src={arrowDown}
                  alt="arrow_Down"
                  className="arrowDown center"
                  
                />
        </div>
        <br></br>
        <div className="second_row">
        <Slider {...sliderSettings}>
          {Users.map((item) => (
            <div key={item.id_user}>
              <Card style={{ width: "18rem" }} className="h-100">
                <img
                  src={item.availability_user === 1 ? onlineIcon : offlineIcon}
                  alt={item.availability_user === 1 ? "Online" : "Offline"}
                  className="online-icon"
                  style={{
                    width: "30%",
                    marginBottom: "-40px",
                    padding: "10px 0px 0px 10px",
                    zIndex: 1,
                  }}
                />

                <img
                  alt="Sample"
                  src={`${process.env.REACT_APP_APIURL}/storage/workshop/${item.photo_user}`}
                />
                <GradientCardBody>
                  <div
                    className="satisfaction-header"
                    style={{ marginBottom: "0px", textAlign: "end" }}>
                    <h6>
                      <b>Satisfaction clients</b>
                    </h6>
                  </div>
                  <div
                    className="custom-rating-container"
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}>
                    <p
                      className="text-medium"
                      style={{
                        fontFamily: "Roboto",
                        fontSize: "26px",
                        fontWeight: 500,
                        lineHeight: "26px",
                        letterSpacing: "0em",
                        textAlign: "center",
                        marginRight: "10px",
                        color: "#66534D",
                        marginBottom: "0",
                      }}>
                      4 / 5
                    </p>
                    <Rating
                      count={5}
                      value={4}
                      size={20}
                      edit={true}
                      activeColor="#F3778F"
                      inactiveColor="#C4C4C4"
                    />
                  </div>
                  <CardTitle
                    tag="h5"
                    style={{
                      color: "#66534D",
                      textAlign: "center",
                      fontSize: "26px",
                    }}>
                    <b>{item.nom_user}</b>
                  </CardTitle>
                  <CardSubtitle
                    className="mb-2 text-muted"
                    tag="h6"
                    style={{
                      color: "#66534D",
                      textAlign: "center",
                      fontSize: "18px",
                    }}>
                    {item.title_user}
                  </CardSubtitle>
                  <center>
                    <NavLink
                      to={`/intervenants/${item.nom_user}-${item.prenom_user}`}
                      className="btn btn-sm"
                      onClick={() => handleEnSavoirPlusClick(item.id_user)}>
                      En savoir +
                    </NavLink>
                  </center>
                </GradientCardBody>
              </Card>
            </div>
          ))}
        </Slider>
        </div>
        <div className="div-more">
          <center>
            <button
              className="btn-more"
              onClick={() => navigate("/intervenants")}>
              Voir plus d’intervenants
            </button>
          </center>
        </div>
      </section>
    </div>
  );
}

export default Header;
