import React, { useState, useEffect } from "react";
import { Dialog, DialogContent } from "@mui/material";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { StaticDateTimePicker } from "@mui/x-date-pickers/StaticDateTimePicker";
import frLocale from "date-fns/locale/fr";
import dayjs from "dayjs";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import { pickersLayoutClasses } from "@mui/x-date-pickers/PickersLayout";
import { ThemeProvider, createTheme } from "@mui/material";
import GlobalContext from "../../../../../../../Context/GlobalContext";
import { useNavigate } from "react-router-dom";
import axios from "../../../../../../../Utils/axios";
import { toast, ToastContainer } from "react-toastify";
import LocalStorage from "../../../../../../../Utils/localStorageService";
import { Spinner } from "reactstrap";
import { Box, Button } from "@mui/material";
import { Typography } from "@mui/material";
import { IconButton } from "@mui/material";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
const theme = createTheme({
  components: {
    MuiTypography: {
      styleOverrides: {
        overline: {
          "&.MuiTypography-root": {
            display: "none",
          },
        },
      },
    },
  },
});

export default function Calendar(props) {
  const [dateRes, setDateRes] = React.useState(null);
  const [loading, setLoading] = React.useState(false);
  const [etatAbn, setEtatAbn] = useState(null);
  const [nbrOffre, setnbrOffre] = useState(null);
  const [idClient, setIdClient] = useState(null);
  const context = React.useContext(GlobalContext);
  const navigate = useNavigate();

  const handleCloseCalendar = () => {
    setDateRes(null);
    props.setOpenCalendar(false);
  };

  const shouldDisableDate = (day) => {
    try {
      // Check if there are available time slots
      if (props.offre.available_slots.length === 0) {
        return true; // Disable all dates if there are no available time slots
      }

      const formattedDate = dayjs(day).startOf("day");
      const isDisabled = !props.availableDates.some((date) =>
        formattedDate.isSame(date, "day")
      );

      return isDisabled;
    } catch (error) {
      console.error("Error:", error);
      return true; // Disable date in case of error
    }
  };

  const shouldDisableTime = (time) => {
    // Disable 3:00 PM on November 18th
    const disabledTime = dayjs("2023-11-18T16:00:00.000Z");
    return time.isSame(disabledTime, "minute");
  };

  useEffect(() => {
    const fetchClientData = async () => {
      const token = localStorage.getItem("access_token_story"); // Adjust the key if necessary
      if (token) {
        try {
          const clientData = await axios.get(
            `${process.env.REACT_APP_APIURL}/client/g/get/p/profile`,
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          );

          if (clientData.data.status === "success") {
            const result = clientData.data.result;
            setEtatAbn(result.etat_abn);
            setIdClient(result.id_client);
            setnbrOffre(result.nbr_offre);

            // Log etatAbn and nbrOffre after setting them
            console.log("EtatAbn:", result.etat_abn);
            console.log("NbrOffre:", result.nbr_offre);
          } else {
            console.error("Failed to fetch client data.");
          }
        } catch (error) {
          console.error("Error fetching client data:", error);
        }
      }
    };

    fetchClientData(); // Call the async function
  }, []);
  const handlePaiementReservation = () => {
    if (!context.isLoggedIn) {
      navigate("/signup");
      return;
    }

    // Vérifiez si une date a été sélectionnée
    if (!selectedTime) {
      toast.error(
        "Veuillez indiquer l'heure souhaitée pour votre réservation."
      );
      return;
    }

    setLoading(true);

    const formattedDateRes = dayjs(selectedTime).format("YYYY-MM-DD HH:mm:ss");
    setDateRes(formattedDateRes);
    const token = LocalStorage.getAccessToken();

    // If etat_abn is 1, use the special API
    if (
      etatAbn === 1 &&
      nbrOffre !== 0 &&
      props.offre.is_accessible_participant === 1
    ) {
      console.log("Processing subscription with existing offers");

      const data = {
        id_offre: props.offre.offre.id_offre, // Assuming id_offre is the correct parameter
        id_client: idClient, // Assuming this is the id of the client
        start_time_reservation: formattedDateRes,
      };
      axios
        .post(
          `${process.env.REACT_APP_APIURL}/process-order-offre-abonner/public/c`,
          data,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((response) => {
          setLoading(false);

          // Affiche le message de succès immédiatement
          toast.success(
            "Votre réservation a été effectuée avec succès !Vous avez utilisé un jeton de votre abonnement."
          );

          // Définit un message de confirmation à inclure dans l'URL de redirection
          const message = encodeURIComponent(
            "Votre réservation a été effectuée avec succès ! Vous allez recevoir un message de confirmation de votre réservation."
          );

          // Redirige vers la page d'accueil avec le message après 35 secondes (35000 ms)
          setTimeout(() => {
            window.location.href = `/message?message=${message}`;
          }, 6000);
        })
        .catch((error) => {
          setLoading(false);
          if (error.response) {
            toast.error(error.response.data.error);
          } else if (error.request) {
            toast.error("Aucune réponse du serveur. Veuillez réessayer.");
          } else {
            toast.error("Une erreur inattendue s'est produite.");
          }
        });
    } else if (
      etatAbn === 1 &&
      nbrOffre === 0 &&
      props.offre.is_accessible_participant === 1
    ) {
      console.log("Subscription active but no offers left");

      // Keep the current reservation method for users with etat_abn === 0
      const data = {
        itemToBuyId: props.offre.offre.id_offre,
        itemToBuyType: "OFFER",
        startTimeReservation: formattedDateRes,
        token: token,
      };

      axios
        .post(
          `${process.env.REACT_APP_BILLING_URL}/create-checkout-session`,
          data,
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        )
        .then((response) => {
          setLoading(false);
          window.location.href = response.data.url;
        })
        .catch((error) => {
          setLoading(false);
          if (error.response) {
            toast.error(error.response.data.error);
          } else if (error.request) {
            toast.error("No response from server. Please try again.");
          } else {
            toast.error("An unexpected error occurred.");
          }
        });
    } else if (etatAbn === 0 || etatAbn === null) {
      console.log("No active subscription");

      // Keep the current reservation method for users with etat_abn === 0
      const data = {
        itemToBuyId: props.offre.offre.id_offre,
        itemToBuyType: "OFFER",
        startTimeReservation: formattedDateRes,
        token: token,
      };

      axios
        .post(
          `${process.env.REACT_APP_BILLING_URL}/create-checkout-session`,
          data,
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        )
        .then((response) => {
          setLoading(false);
          window.location.href = response.data.url;
        })
        .catch((error) => {
          setLoading(false);
          if (error.response) {
            toast.error(error.response.data.error);
          } else if (error.request) {
            toast.error("No response from server. Please try again.");
          } else {
            toast.error("An unexpected error occurred.");
          }
        });
    } else {
      console.log("Missing etatAbn or nbrOffre values at the time of check.");

      setLoading(false);
      toast.error("Invalid subscription status.");
    }
  };

  const renderDot = (borderColor, label, labelStyle = {}) => {
    return (
      <div style={{ display: "flex", alignItems: "center", gap: "0.2em" }}>
        <div
          style={{
            width: "1rem",
            height: "1rem",
            borderRadius: "50%",
            border: `0.125em solid ${borderColor}`,
            ...labelStyle,
          }}></div>
        <span style={{ fontSize: "0.9em" }}>{label} </span>
      </div>
    );
  };
  const [currentView, setCurrentView] = useState("month"); // Initial view

  const renderToolBar = (props) => {
    const { availableDates } = props;
    const currentYear = dayjs().year(); // Get the current year

    return (
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          padding: "0.5em",
          flexWrap: "wrap",
          alignItems: "center",
          marginBottom: "1em", // Adjust the space below the toolbar
        }}>
        {/* Status dots */}
        <div style={{ display: "flex", alignItems: "center", gap: "5em" }}>
          {renderDot("#4caf50", "Disponible")}
          {renderDot("#ccc", "Antérieure")}
          {renderDot("black", "Aujourd'hui")}
        </div>

        {/* Display the current year */}
        <div style={{ display: "flex", alignItems: "center" }}>
          <span style={{ fontWeight: "bold", fontSize: "1.8em" }}>
            {currentYear}
          </span>
        </div>
      </div>
    );
  };

  const [view, setView] = useState("month"); // Etat pour suivre la vue actuelle

  const handleViewChange = (newView) => {
    if (newView === "day") {
      // Si on est en vue "jour", on revient à la vue "mois"
      setView("month");
    } else {
      // Si on n'est pas en vue "jour", on laisse la vue actuelle
      setView(newView);
    }
  };
  const handleBackToMonth = () => {
    setView("month"); // Switch back to the month view
  };
  const handleDateChange = (date) => {
    const formattedDate = dayjs(date).format("YYYY-MM-DD");
    setSelectedDate(formattedDate);
  };

  const ActionList = () => {
    const actions = [
      { text: "Annuler", method: handleCloseCalendar },
      { text: "Réserver", method: handlePaiementReservation },
    ];

    return (
      <List
        style={{
          display: "flex",
          width: "100%",
          justifyContent: "center",
          marginTop: "20px", // Un peu d'espace entre le calendrier et les boutons
        }}>
        {actions.map(({ text, method }) => (
          <ListItem key={text} disablePadding>
            <ListItemButton
              onClick={method}
              style={{
                display: "flex",
                justifyContent: "center",
                padding: "8px 16px",
                margin: "0 10px",
                backgroundColor: "#f3778f", // Couleur verte
                color: "#fff",
                borderRadius: "10px",
              }}>
              {text === "Réserver" && loading ? (
                <span>Loading...</span> // Remplacez-le par votre composant spinner
              ) : (
                <ListItemText primary={text} />
              )}
            </ListItemButton>
          </ListItem>
        ))}
      </List>
    );
  };
  const [selectedDate, setSelectedDate] = useState(null); // State to store selected date
  const [selectedTime, setSelectedTime] = useState(null); // State to store selected time slot

  // Handler to store the selected time in state
  const handleTimeSlotSelection = (time) => {
    setSelectedTime(time);
  };

  const RenderAvailableTimeSlots = ({
    availableTimeSlots,
    selectedDate,
    onTimeSelect,
    selectedTime,
  }) => {
    // Format selected date to match the slot's date format
    const formattedSelectedDate = selectedDate
      ? dayjs(selectedDate).format("YYYY-MM-DD")
      : null;

    // If no date is selected, show a message to prompt the user
    if (!selectedDate) {
      return (
        <div>
          <p>Veuillez sélectionner un mois puis un jour.</p>
        </div>
      );
    }

    // Filter slots based on the selected date
    const filteredSlots = availableTimeSlots.filter((slot) => {
      const slotDate = dayjs(slot).format("YYYY-MM-DD");
      return slotDate === formattedSelectedDate;
    });

    return (
      <div>
        {/* Only display this message if there are available slots */}
        {filteredSlots.length > 0 && (
          <p>
            Sélectionnez un créneau horaire disponible pour la date choisie,
            puis cliquez sur "Réserver".
          </p>
        )}

        <div
          style={{
            display: "flex",
            flexWrap: "wrap",
            gap: "10px",
            marginTop: "0em",
          }}>
          {filteredSlots.length > 0 ? (
            filteredSlots.map((slot, index) => {
              const slotDateTime = dayjs(slot); // Create a dayjs object for the full date-time
              const slotDate = slotDateTime.format("YYYY-MM-DD"); // Format for the date
              const slotTime = slotDateTime.format("HH:mm"); // Format for the time

              const isSelected =
                selectedTime && dayjs(selectedTime).isSame(slot, "minute"); // Check if the current slot is selected

              return (
                <button
                  key={index}
                  onClick={() => onTimeSelect(slot)} // Call onTimeSelect when a slot is selected
                  style={{
                    padding: "8px 16px",
                    border: "2px solid #4caf50",
                    backgroundColor: isSelected ? "#4caf50" : "#fff", // Change background if selected
                    color: isSelected ? "#fff" : "#4caf50", // Change text color if selected
                    borderRadius: "8px",
                    cursor: "pointer",
                  }}>
                  {slotDate} {slotTime} {/* Display the full date and time */}
                </button>
              );
            })
          ) : (
            // Message if no slots are available
            <p>Aucun créneau disponible pour cette date.</p>
          )}
        </div>
      </div>
    );
  };

  const isAvailable = (date) => {
    const now = dayjs().startOf("day");
    const formattedDate = dayjs(
      `${date.year}-${date.month + 1}-${date.day}`
    ).startOf("day");
  
    // Vérifier si la date est dans le passé
    if (formattedDate.isBefore(now, "day")) {
      return false;
    }
  
    // Si la date est aujourd'hui, vérifier les disponibilités restantes
    if (formattedDate.isSame(now, "day")) {
      return props.availableDates.some((availableDate) => {
        const availableDateObj = dayjs(availableDate);
        return availableDateObj.isAfter(dayjs()); // Vérifie s'il reste du temps disponible aujourd'hui
      });
    }
  
    // Sinon, vérifier les dates futures normalement
    return props.availableDates.some((availableDate) => {
      const availableDateObj = dayjs(availableDate).startOf("day");
      return formattedDate.isSame(availableDateObj, "day");
    });
  };
  
  

  const [selectedYear, setSelectedYear] = useState(dayjs().year()); // Initialiser avec l'année actuelle
  const getPickerStyles = (currentView, availableDates, selectedYear) => {
    // Filtrer les années disponibles
    const availableYears = availableDates
      .map((date) => dayjs(date).year()) // Extraire les années des dates disponibles
      .filter((year, index, self) => self.indexOf(year) === index); // Supprimer les doublons
  
    // Affichage pour vérifier la valeur de `availableYears`
    console.log("Available Years:", availableYears);
  
    // Filtrer les mois disponibles pour l'année sélectionnée
    const availableMonths = availableDates
      .filter((date) => dayjs(date).year() === selectedYear)
      .map((date) => dayjs(date).month())
      .filter((month, index, self) => self.indexOf(month) === index);
  
    return {
      ".MuiPickersMonth-root": {
        ...(currentView === "month" && {
          "&:nth-of-type(n)": { // Utilisation de :nth-of-type pour les mois
            ...Array.from({ length: 12 }).reduce((acc, _, i) => {
              const month = dayjs(`${selectedYear}-${i + 1}-01`).format("YYYY-MM");
              if (availableMonths.includes(i)) {
                acc[`&:nth-of-type(${i + 1})`] = {
                  backgroundColor: "rgba(27, 189, 80, 0.19)", // Fond vert clair pour les mois disponibles
                  color: "#4caf50", // Texte vert
                };
              } else {
                acc[`&:nth-of-type(${i + 1})`] = {
                  backgroundColor: "rgba(255, 0, 0, 0.1)", // Fond rouge clair pour les mois non disponibles
                  color: "red", // Texte rouge
                };
              }
              return acc;
            }, {}),
          },
        }),
      },
  
      ".MuiPickersYear-root": {
        ...(currentView === "year" && {
          // Utilisation d'un style générique pour appliquer un fond vert clair sur les années disponibles
          "&.MuiPickersYear-root": {
            backgroundColor: "#fff", // Fond par défaut
            color: "#000", // Texte par défaut
          },
          // Appliquer un style vert clair pour les années disponibles
          ...availableYears.reduce((acc, year) => {
            acc[`&:nth-of-type(${year - (selectedYear - 6) + 1})`] = {
              backgroundColor: "rgba(27, 189, 80, 0.19)", // Fond vert clair pour l'année disponible
              color: "#4caf50", // Texte vert
            };
            return acc;
          }, {}),
        }),
  
        // Appliquer un style pour l'année sélectionnée en vert (texte et fond)
        "&.Mui-selected": {
          backgroundColor: "#4caf50 !important", // Fond vert pour l'année sélectionnée
          color: "#fff !important", // Texte blanc
        },
      },
    };
  };
  
  
  return (
    <React.Fragment>
      <Dialog
        open={props.isOpen}
        onClose={handleCloseCalendar}
        maxWidth="lg" // Largeur maximale (lg, sm, md, xl ou false pour pleine largeur)
        fullWidth // Permet au modal de prendre toute la largeur possible
        PaperProps={{
          style: {
            width: "100%", // Vous pouvez ajuster la largeur selon vos besoins
            maxHeight: "90vh", // Limite la hauteur à 90% de la hauteur de la fenêtre
          },
        }}>
        <ToastContainer
          position="top-right"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
        <DialogContent>
          <LocalizationProvider dateAdapter={AdapterDayjs} locale={frLocale}>
            <ThemeProvider theme={theme}>
              {/* Wrapper for responsiveness */}
              <Box
                sx={{
                  display: "flex",
                  flexDirection: { xs: "column", sm: "column", md: "row" }, // Stack on smaller screens, row on medium and up
                  gap: 2, // Add spacing between sections
                  alignItems: "flex-start",
                }}
              >
                {/* Left Section: Calendar */}
                <Box
                  sx={{
                    flex: 1,
                    marginRight: { xs: 0, md: "20px" }, // Add margin on larger screens
                    marginBottom: { xs: 2, md: 0 }, // Add spacing on smaller screens
                  }}
                >
                  <Box
                    sx={{
                      width: "100%",
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  >
                    <Typography
                      variant="h6"
                      sx={{
                        color: "#4caf50",
                        fontSize: { xs: "14px", sm: "16px", md: "18px" }, // Responsive font sizes
                        textAlign: "center",
                      }}
                    >
                      Dans le calendrier, sélectionnez un mois disponible en vert puis
                      choisissez un jour.
                    </Typography>
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "row", // Default direction
                      justifyContent: "space-between", // Distribute space between items
                      gap: "10px", // Space between components
                      flexWrap: "wrap", // Allow wrapping for smaller screens
                    }}
                  >
                    <StaticDateTimePicker
                    ampm={false}
                    format="HH:mm"
                    views={["year", "month", "day"]}
                    openTo="month"
                    disablePast
                    onChange={handleDateChange}
                    view={view}
                    onViewChange={(newView) => setView(newView)}
                    onYearChange={(newYear) => setSelectedYear(newYear.year())}
                    slotProps={{
                      layout: {
                        sx: getPickerStyles(
                          currentView,
                          props.availableDates,
                          selectedYear
                        ),
                      },
                      day: (date) => {
                        const isAvailableDay = isAvailable(date, "day");
                        return {
                          sx: {
                            border: `2px solid ${isAvailableDay ? "#4caf50" : "red"}`,
                            color: isAvailableDay ? "#4caf50" : "red",
                            "&:hover": isAvailableDay
                              ? {
                                  color: "#fff",
                                  backgroundColor: "#4caf50",
                                }
                              : undefined,
                          },
                        };
                      },
                    }}
                    slots={{
                      calendarHeader: () => (
                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                            paddingBottom: 2,
                          }}
                        >
                          <div>
                            <IconButton onClick={() => setView("year")}>
                              <Typography
                                variant="body1"
                                style={{ marginLeft: 5 }}
                              >
                                Année
                              </Typography>
                            </IconButton>
                            <IconButton onClick={() => setView("month")}>
                              <ArrowDropDownIcon />
                              <Typography
                                variant="body1"
                                style={{ marginLeft: 5 }}
                              >
                                Mois
                              </Typography>
                            </IconButton>
                            <IconButton onClick={() => setView("day")}>
                              <ArrowRightIcon />
                              <Typography
                                variant="body1"
                                style={{ marginLeft: 5 }}
                              >
                                Jour
                              </Typography>
                            </IconButton>
                          </div>
                        </Box>
                      ),
                    }}
                    sx={{
                      width: { xs: "90%", sm: "70%", md: "50%" }, // Responsive width
                      maxWidth: "400px", // Limit max width
                      "& .MuiCalendarPicker-root": {
                        fontSize: { xs: "0.8rem", sm: "0.9rem", md: "1rem" }, // Responsive font size
                      },
                      "& .MuiPickersBasePicker-root": {
                        width: "100%",
                        fontSize: { xs: "0.8rem", sm: "0.9rem", md: "1rem" },
                      },
                      "& .MuiTypography-root": {
                        fontSize: { xs: "0.8rem", sm: "0.9rem", md: "1rem" }, // Adjust typography
                      },
                      "& .MuiPickersDay-root": {
                        fontSize: { xs: "0.75rem", sm: "0.85rem", md: "0.9rem" }, // Adjust day font size
                      },
                      "& .MuiPickersCalendarHeader-root": {
                        fontSize: { xs: "0.8rem", sm: "0.9rem", md: "1rem" },
                      },
                      "& .MuiIconButton-root": {
                        fontSize: { xs: "0.8rem", sm: "0.9rem", md: "1rem" },
                      },
                    }}
                  />
                  </Box>
                </Box>

                {/* Right Section: Available Time Slots */}
                <Box sx={{ flex: 1 }}>
                  <RenderAvailableTimeSlots
                    availableTimeSlots={props.offre.available_slots}
                    selectedDate={selectedDate}
                    onTimeSelect={handleTimeSlotSelection}
                    selectedTime={selectedTime}
                  />
                  <ActionList />
                  {view === "day" && (
                    <ListItem disablePadding>
                      <ListItemButton
                        onClick={handleBackToMonth}
                        sx={{
                          display: "flex",
                          justifyContent: "center",
                          padding: "8px 16px",
                          margin: "0 10px",
                          backgroundColor: "#f3778f", // Customize color
                          color: "#fff",
                          borderRadius: "10px",
                        }}
                      >
                        Retour vers les mois
                      </ListItemButton>
                    </ListItem>
                  )}
                </Box>
              </Box>
            </ThemeProvider>
          </LocalizationProvider>
        </DialogContent>

      </Dialog>
    </React.Fragment>
  );
}
